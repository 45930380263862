<template>
  <div class="app">
    <img
      src="../../../assets/allImage/carLife/toutu_shenghuozixun@1x.png"
      alt=""
    />
    <div class="app-content">
      <div class="item_son">
        <div class="obj_box" v-for="item in list" :key="item.id">
          <img
            class="srcImg"
            src="../../../assets/allImage/companyProfile/wenzitubiao@2x.png"
            alt=""
          />
          <div class="title_sk">
            {{ item.title }}
          </div>
          <div class="content_sk">
            <div>{{ item.time }}</div>
            <div class="vd_ds">查看详情></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination_block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="400"
      >
      </el-pagination>
    </div>
    <BottomField></BottomField>
  </div>
</template>

<script>
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
export default {
  components: {
    BottomField,
  },
  data() {
    return {
      currentPage4: 4,
      imageSize: 'cover',
      list: [
        {
          id: 0,
          title: '关于公开征集2022年供应商入库的公告',
          time: '2023-01-01',
        },
        {
          id: 1,
          title:
            '广西捷通高速科技有限公司线上微信公众号广告投放合作商招募项目结果公示',
          time: '2023-02-02',
        },
        {
          id: 2,
          title: '广西捷通高速科技有限公司ETC+增值平台服务商公开招募公告',
          time: '2023-03-03',
        },
        {
          id: 3,
          title: '“广西捷通”微信公众号线上广告投放合作商招募公告',
          time: '2023-04-04',
        },
        {
          id: 4,
          title: '广西捷通高速科技有限公司ETC代理商公开招募公告',
          time: '2023-05-05',
        },
        {
          id: 5,
          title: '2019年第三次广西高速公路用户卡（CPU卡）采购项目结果公示',
          time: '2023-06-06',
        },
        {
          id: 6,
          title:
            '2019年第四次广西高速公路ETC客户电子标签（OBU）采购项目结果公示',
          time: '2023-07-07',
        },
        {
          id: 7,
          title: '2019年第四次广西高速公路ETC客户电子标签（OBU）采购公告',
          time: '2023-08-08',
        },
        {
          id: 8,
          title: '2019年第三次用户（CPU）卡采购竞争性谈判公告',
          time: '2023-09-09',
        },
        {
          id: 9,
          title:
            '广西捷通高速科技有限公司2019年ETC数据中心机房应急改造设备采购项目结果公示',
          time: '2023-10-10',
        },
      ],
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  .app-content {
    width: 100%;
    display: flex;
    justify-content: center;

    .item_son {
      width: 80%;
      padding: 0 5rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 0.9375rem;
      .obj_box {
        width: 31.25rem;
        height: 7.5rem;
        border-radius: 0.25rem;
        padding: 0.9375rem;
        margin-top: 0.9375rem;
        background-color: #fff;
        position: relative;
        .srcImg {
          width: 1.25rem;
          position: absolute;
          left: 0;
          top: 0;
        }
        .title_sk {
          width: 100%;
          height: 2.1875rem;
          margin-top: 0.625rem;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 2.1875rem;
          text-align: left;
        }
        .content_sk {
          width: 100%;
          height: 2.1875rem;
          margin-top: 0.625rem;
          font-size: 0.75rem;
          line-height: 2.1875rem;
          border-top: 0.0625rem dashed #b9b7b7;
          display: flex;
          justify-content: space-between;
          .vd_ds {
            color: #0aa4ee;
          }
        }
      }
    }
  }
  .pagination_block {
    width: 80%;
    height: 3.125rem;
    margin: 0 auto;
    text-align: right;
    padding-right: 5rem;
  }
}
</style>
